import {adminApi} from "../../aaxios";

async function postCustomer(sessionId, name, phoneNumber) {
    try {
        const response = await adminApi.put('/session/user_info', {
            "session_id": sessionId,
            "nickname": name,
            "phone_number": phoneNumber
        });
        if (response.status === 200 || response.status === 201) {
            return {
                success: true,
                message: '데이터가 성공적으로 전송되었습니다.'
            }
        }
        return {
            success: false,
            message: '데이터 전송에 실패 했습니다.'
        }
    } catch (error) {
        return {
            success: false,
            message: '서버 점검 중 재시도'
        }
    }
}


export default function useConsultation() {

    async function submitUserInfo({
                                      sessionId,
                                      name,
                                      contact
                                  }) {
        return await postCustomer(
            sessionId,
            name,
            contact
        )
    }

    return {
        submitUserInfo
    }
}