import axios from 'axios';


function createAdminApi(){
    let apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    if(!apiBaseUrl){
        apiBaseUrl ="http://0.0.0.0:8000/api/v1"
    }
   return  axios.create({
        baseURL: apiBaseUrl,
    });
}



const adminApi = createAdminApi()

const camelApi = axios.create({
    baseURL: process.env.REACT_APP_CAMEL_API_BASE_URL,
});

export {
    adminApi,
    camelApi,
};